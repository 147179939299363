import "./App.css";
import { useState } from "react"
import Navbar from "./Navbar";
import Content from "./Content";

const Home = ({ username, onLogout, email, uID }) => {
  const [transferUser, setTransferUser ] = useState(false);
  
  function startWorkout() {
    setTransferUser(true)
  }

  return (
      <div>
          <Navbar username={username} onLogout={onLogout} email={email} uID={uID} />
          {transferUser ? (
            <div>
              <Content username={username} email={email} uID={uID} />
            </div>
          ) : (
  
            <div className="App">                
              <div className="content-wrapper">
                <h1>Welcome, {username}!</h1>
                
                <div className="container">
                  <div className="slider-container-left">
                    <div className="slidecontainer">
                      <h2 style={{ writingMode: 'vertical-lr', textOrientation: 'mixed'}}> Placeholder Text</h2>
                    </div>
                  </div>
                  <div className="video-container">
                    {/* <video className="input_video" ref={webcamRef} style={{ opacity: videoOpacity, zIndex: 998, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', transition: 'opacity 0.3s' }} /> */}
                    <div className="hidden-video-text" style={{ zIndex: 1, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                        <div>
                          <h1>Start Your Workout</h1>
                          <p>To Start Your Workout, Tap the Button Below</p>
                          <br />
                          <button style={{ width: "75%", backgroundColor: "grey" }} onClick={startWorkout} >Start Your Workout</button> 
                        </div>
                    </div>
                    
                  </div>
                  <div className="slider-container-right">
                      <div className="slidecontainer">
                        <h2 style={{ writingMode: 'vertical-rl', textOrientation: 'mixed'}}> Placeholder Text</h2>
                      </div>
                  </div>
                </div>
              </div>
          </div>
          )}
      </div>
      
  );
}

export default Home;