import React, { useState } from 'react';
import FirebaseLogin from './FirebaseLogin';
import { signOut } from "firebase/auth";
import {auth} from './firebase';
import Home from './Home';
import { useNavigate } from 'react-router-dom';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [email, setUserEmail] = useState('');
  const [uID, setUID] = useState('');
  const navigate = useNavigate();


  const handleUID = (uID) => {
    // setIsLoggedIn(true);
    setUID(uID);
  };

  const handleEmail = (email) => {
    // setIsLoggedIn(true);
    setUserEmail(email);
  };

  const handleLogin = (username) => {
    setIsLoggedIn(true);
    setUsername(username);
  };

  const handleLogout = () => {               
    signOut(auth).then(() => {
    // Sign-out successful.
        navigate("/")
        setIsLoggedIn(false)
        console.log("Signed out successfully")
    }).catch((error) => {
    // An error happened.
    console.log(error)
    });
}

  return (
    <div>
      {isLoggedIn ? (
        <div>
          <Home username={username} onLogout={handleLogout} email={email} uID={uID} />
        </div>
      ) : (
        <FirebaseLogin onLogin={handleLogin} onUserEmail={handleEmail} uID={handleUID}/>
      )}
    </div>
  );
};

export default App;