import React, {useState} from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from './firebase';
 
const FirebaseLogin = ({ onLogin, onUserEmail }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
       
    const onLoginUser = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            onUserEmail(user.email)
            onLogin(user.displayName)
            console.log(user);
        })
        .catch((error) => {
          // eslint-disable-next-line
            const errorCode = error.code;
            // eslint-disable-next-line
            const errorMessage = error.message;
            // console.log(errorCode)
            if (error.code === "auth/too-many-requests" ){
              error.message = "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later."
            }
            if (error.code === "auth/invalid-credential" ){
              error.message = "Invalid Username or Password"
            }
            setError(error.message);
            // console.error('Login error:', error);
        });
    }
    return (
      <div className="login-container">
        <span className="background-image" />
        <div className="login-form">
          <h2>
            <img
              alt=""
              className="login-items"
              width="25%"
              style={{ textAlign: "center" }}
              src="Workout Buddy Logo.png"
            ></img>
            Workout Buddy
          </h2>
          <form>
            <div className="form-group margin-top">
              <label className="center-text" htmlFor="email-address">
                Username
              </label>
              <input
                name="email"
                type="email"
                id="email-address"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group margin-top">
              <label className="center-text" htmlFor="password">
                Password
              </label>
              <input
                name="password"
                type="password"
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button
              className="margin-top btn center-text"
              type="submit"
              onClick={onLoginUser}
            >
              <span>Login</span>
            </button>
          </form>
          <p>
            By Clicking "Login" you agree to <br />
            <a
              href="https://workoutbuddy.online/Terms.html"
            >
              <span
                style={{textDecoration: "underline", color: "white" }}
              >
                Terms of Service
              </span>
            </a>
            <bold> · </bold>
            <a
              href="https://workoutbuddy.online/Private%20Policy.html"
            >
              <span
                style={{textDecoration: "underline", color: "white" }}
              >
                Privacy Policy
              </span>
            </a>
          </p>
          {error && <p className="error-message">{error}</p>}{" "}
          {/* Render error message if error exists */}
        </div>
      </div>
    );
}
 
export default FirebaseLogin;