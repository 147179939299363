import React from 'react';
import '../Login.css'; // Import your CSS file for styling
import Navbar from '../Navbar';

const Error = ({ username, onLogout, error }) => {
  return (
    <div>
        <Navbar username={username} onLogout={onLogout} />
        <div className="login-container">
            <span className="background-image" />
            <h1>Error 404: Page not Found</h1>
        </div>
    </div>
  );
};

export default Error;
