import "./App.css";
import * as pose from '@mediapipe/pose'
import smoothLandmarks from 'mediapipe-pose-smooth'; // ES6
import * as cam from "@mediapipe/camera_utils"
import * as drawingUtils from "@mediapipe/drawing_utils"
import { useRef, useEffect, useState } from "react"

const Content = ({ username, onLogout, email, uID }) => {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  let camera = null;
  const [didLoad, setDidLoad] = useState(false);
  const [rightAngle, setRightAngle] = useState(null);
  const [rightCounter, setRightCounter] = useState(0);
  let right_stage = true;
  let right_counter = 0;
  const [leftAngle, setleftAngle] = useState(null);
  const [leftCounter, setleftCounter] = useState(0);
  let left_stage = true;
  let left_counter = 0;
  const [optionOpacity] = useState(null);
  const [videoOpacity, setVideoOpacity] = useState(1);

  function toggleVideoOpacity() {
    setVideoOpacity((prevOpacity) => (prevOpacity === 1 ? 0 : 1));
    setleftCounter(0);
    setRightCounter(0);
  }

  function onResults(results) {
    const canvasElement = canvasRef.current;
    const canvasCtx = canvasElement.getContext("2d");

    canvasCtx.save();
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
    canvasCtx.drawImage(
      results.image,
      0,
      0,
      canvasElement.width,
      canvasElement.height
    );

    if (results.poseLandmarks) {
      drawingUtils.drawConnectors(
        canvasCtx,
        results.poseLandmarks,
        pose.POSE_CONNECTIONS,
        { visibilityMin: 0.65, color: "white" }
      );
      drawingUtils.drawLandmarks(
        canvasCtx,
        Object.values(pose.POSE_LANDMARKS_LEFT).map(
          (index) => results.poseLandmarks[index]
        ),
        { visibilityMin: 0.65, color: "white", fillColor: "rgb(255,138,0)" }
      );
      drawingUtils.drawLandmarks(
        canvasCtx,
        Object.values(pose.POSE_LANDMARKS_RIGHT).map(
          (index) => results.poseLandmarks[index]
        ),
        { visibilityMin: 0.65, color: "white", fillColor: "rgb(0,217,231)" }
      );
      drawingUtils.drawLandmarks(
        canvasCtx,
        Object.values(pose.POSE_LANDMARKS_NEUTRAL).map(
          (index) => results.poseLandmarks[index]
        ),
        { visibilityMin: 0.65, color: "white", fillColor: "white" }
      );

      // Calculate angle of right elbow
      const rightShoulder = results.poseLandmarks[pose.POSE_LANDMARKS.RIGHT_SHOULDER];
      const rightElbow = results.poseLandmarks[pose.POSE_LANDMARKS.RIGHT_ELBOW];
      const rightWrist = results.poseLandmarks[pose.POSE_LANDMARKS.RIGHT_WRIST];
      // console.log(rightShoulder)

      if (rightShoulder && rightElbow && rightWrist) {
        const right_angle = calculateAngle(rightShoulder, rightElbow, rightWrist);
        // console.log("Angle of right elbow:", right_angle);
        setRightAngle(right_angle);

        // Define initial values for right_stage and right_counter

        // Check if the right_angle is greater than 160
        if (right_angle > 130) {
          right_stage = false; // Update right_stage to "down"
          // console.log("Right Stage: RIGHT ANGLE", right_stage);
        }
        //  && right_stage == true
        // Check if the right_angle is less than 30 and right_stage is "down"
        if (right_angle < 30 && right_stage === false ) {
          right_stage = true; // Update right_stage to "up"
          right_counter += 1; // Increment right_counter
          // console.log("Right Angle",right_angle);
          // console.log("Right Counter:", right_counter);
          setRightCounter(right_counter)
        }
      } else {
        console.error("Failed to calculate angle. Missing keypoints.");
      }
      // Calculate angle of right elbow
      const leftShoulder = results.poseLandmarks[pose.POSE_LANDMARKS.LEFT_SHOULDER];
      const leftElbow = results.poseLandmarks[pose.POSE_LANDMARKS.LEFT_ELBOW];
      const leftWrist = results.poseLandmarks[pose.POSE_LANDMARKS.LEFT_WRIST];
      // console.log(rightShoulder)

      if (leftShoulder && leftElbow && leftWrist) {
        const left_angle = calculateAngle(leftShoulder, leftElbow, leftWrist);
        // console.log("Angle of left elbow:", left_angle);
        setleftAngle(left_angle);

        // Define initial values for left_stage and left_counter

        // Check if the left_angle is greater than 160
        if (left_angle > 130) {
          left_stage = false; // Update left_stage to "down"
          // console.log("left Stage: left ANGLE", left_stage);
        }
        //  && left_stage == true
        // Check if the left_angle is less than 30 and left_stage is "down"
        if (left_angle < 30 && left_stage === false ) {
          left_stage = true; // Update left_stage to "up"
          left_counter += 1; // Increment left_counter
          // console.log("left Angle",left_angle);
          // console.log("left Counter:", left_counter);
          setleftCounter(left_counter)
        }
      } else {
        console.error("Failed to calculate angle. Missing keypoints.");
      }
    } else {
      console.error("No pose landmarks detected.");
    }
    canvasCtx.restore();
  }

  function calculateAngle(shoulder, elbow, wrist) {
    const radians = Math.atan2(wrist.y - elbow.y, wrist.x - elbow.x) -
      Math.atan2(shoulder.y - elbow.y, shoulder.x - elbow.x);
    let angle = Math.abs((radians * 180.0) / Math.PI);
    if (angle > 180.0) {
      angle = 360.0 - angle;
    }
    return angle;
  }

  useEffect(() => {
    if (!didLoad) {
      const mpPose = new pose.Pose({
        locateFile: (file) => {
          return `https://cdn.jsdelivr.net/npm/@mediapipe/pose/${file}`;
        },
      });
      mpPose.setOptions({
        selfieMode: true,
        modelComplexity: 1,
        smoothLandmarks: true,
        enableSegmentation: false,
        smoothSegmentation: true,
        minDetectionConfidence: 0.5,
        minTrackingConfidence: 0.5,
      });
      // eslint-disable-next-line
      camera = new cam.Camera(webcamRef.current, {
        onFrame: async () => {
          const canvasElement = canvasRef.current;
          if (!canvasElement) return; 
          const aspect = window.innerHeight / window.innerWidth;
          let width, height;
          if (window.innerWidth > window.innerHeight) {
            height = window.innerHeight;
            width = height / aspect;
          }
          else {
            width = window.innerWidth;
            height = width * aspect;
          }
          canvasElement.width = width;
          canvasElement.height = height;
          await mpPose.send({ image: webcamRef.current });
        }
      })
      camera.start();

      mpPose.onResults((results) => smoothLandmarks(results, onResults));
      setDidLoad(true)
    }
    return () => {
      if (camera) {
        camera.stop();
      }
    };
  }, [didLoad])
  let rightPercentage = 0;
  rightPercentage = ((160 - rightAngle) / (160 - 30)) * 100
  if (rightPercentage < 0) {
    rightPercentage = 0;
  }
  let leftPercentage = 0;
  leftPercentage = ((160 - leftAngle) / (160 - 30)) * 100
  if (leftPercentage < 0) {
    leftPercentage = 0;
  }

  return (
      <div>
          <div className="App">
              <div className="content-wrapper" style={{opacity: optionOpacity}}>
                <h1>Welcome, {username}!</h1>
                
                <div className="container">
                    <div className="slider-container-left">
                        <div className="slidecontainer">
                            {rightCounter !== null && <h2>Left Hand: {rightCounter}</h2>}
                            {rightPercentage !== null && <input type="range" min="30" max="110" value={rightPercentage} className="slider" id="myRange" disabled />}
                        </div>
                    </div>
                    <div className="video-container">
                        <video className="input_video" ref={webcamRef} style={{ opacity: videoOpacity, zIndex: 998, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', transition: 'opacity 0.3s' }} />
                        <div className="hidden-video-text" style={{ zIndex: 1, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                          <div>
                            <h1>Video Hidden</h1>
                            <p>To Show Video Feed, Tap the Button Below</p>
                          </div>
                        </div>
                        {/* Put Content in the Lower Left of the Video Feed On this Line  */}<div className="video-container-flipped" style={{ position: 'absolute', bottom: -50, right: 0, width: "45%",  zIndex: 9999 }}></div>
                        <div class="checkbox-wrapper video-container-flipped" style={{ position: 'absolute', bottom: -60, zIndex: 9999 }}> {/* Remove margin to accomidate for element on the left of the video feed */}
                          <input value="private" name="slider-switch" id="slider-switch" onClick={toggleVideoOpacity} type="checkbox" class="slider-switch" />
                          <label for="slider-switch">
                            <span class="slider-switch-x-text">Video Feed is </span>
                            <span class="slider-switch-x-toggletext">
                              <span class="slider-switch-x-checked"><span class="slider-switch-x-hiddenlabel">Unchecked: </span>Off</span>
                              <span class="slider-switch-x-unchecked"><span class="slider-switch-x-hiddenlabel">Checked: </span>On</span>
                            </span>
                          </label>
                        </div>
                        {/* <canvas ref={canvasRef} className='output_canvas' style={{ opacity: 0 }}></canvas> */}
                    </div>
                    <div className="slider-container-right">
                        <div className="slidecontainer">
                            {leftCounter !== null && <h2>Right Hand: {leftCounter}</h2>}
                            {leftPercentage !== null && <input type="range" min="30" max="110" value={leftPercentage} className="slider" id="myRange" disabled />}
                        </div>
                    </div>
                </div>
              </div>
          </div>
      </div>
  );
}

export default Content;