import React from 'react';
import './Login.css';
// import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';

const Accounts = () => {
  const location = useLocation();
  // console.log("Accounts:", location.state.id)
  const username = location.state.id;
  const email = location.state.email;

  return (
    <div>
        <Navbar username={username} />
        <div className="login-container">
        <span className="background-image" />
        <h1>Accounts.js <br /> <h6>Username: {username}</h6><h6>Email: {email}</h6></h1>
        </div>
    </div>
  );
};

export default Accounts;