// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyClf_xm-TX5W6g24XD7iIR82m45VacYAjI",
  authDomain: "workout-buddy-firebase.firebaseapp.com",
  projectId: "workout-buddy-firebase",
  storageBucket: "workout-buddy-firebase.appspot.com",
  messagingSenderId: "65667428332",
  appId: "1:65667428332:web:cca77d68ef44b0e0b91ac9",
  measurementId: "G-9ZMCX677K0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth =  getAuth(app);
export default app;