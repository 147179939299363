import React from "react";

import { Link, Outlet } from "react-router-dom";


function Navbar({ username, onLogout, email }) {

  return (
    <>
      <nav className="navbar shadow-sm">
        <a className="nav-items" href="/"><h3 className="nav-items">Workout Buddy <img alt="" width="15%" src="Workout Buddy Logo.png"></img></h3></a>
        <div className="nav-items">
          <div class="dropdown">
            <button class="nav-item nav-logout dropdown-button"><span class="material-symbols-outlined">account_circle</span>{username}</button> 
            <div class="dropdown-content">
              {/* <span class="material-symbols-outlined">account_circle</span> */}
              <Link class="nav-item nav-logout" to="/accounts" state={{id: username, email: email}}>Account</Link>
              <hr />
              <Link onClick={onLogout}>Logout</Link>
            </div>
          </div>
        </div>
      </nav>
      <main>
        <Outlet />
      </main>
    </>
  );
}

export default Navbar;